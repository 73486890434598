
/* eslint-disable no-unused-vars */

import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import { getModule } from 'vuex-module-decorators';
import { PaymentRecordsModule } from '../../store/accounting-payments.module';

import {
  CreateQueryParams,
  SConditionAND,
  SFields,
} from '@dataui/crud-request';

interface URLConstruct<ValueType> {
  (string): ValueType;
}

@Component({
  components: {
    DatePicker,
  },
})
export default class Exports extends Vue {
  searchDocuments: string = '';
  searchPayments: string = '';
  searchDocumentPaymentLinks: string = '';
  timeDeltaMS: number = 0;

  created() {
    this.updateSearchParams();
  }
  get timeRangeMax(): number {
    return 366 * 24 * 3600 * 1000;
  }
  get expURL(): string {
    return '/documents-api/export/';
  }
  get store(): PaymentRecordsModule {
    return getModule(PaymentRecordsModule, this.$store);
  }
  get documentsURL(): URLConstruct<string> {
    return (exportType: string): string => {
      return `/documents-api/accounting/documents/export/${exportType}/documents?${this.searchDocuments}`;
    };
  }
  get paymentsURL(): URLConstruct<string> {
    return (exportType: string): string => {
      return `/documents-api/accounting/payments/export/${exportType}/payments?${this.searchPayments}`;
    };
  }
  get documentLinksURL(): URLConstruct<string> {
    return (exportType: string): string => {
      return `/documents-api/accounting/payments/export/${exportType}/documents-links?${this.searchDocumentPaymentLinks}`;
    };
  }
  msToDays(timeInMS: number): number {
    return timeInMS / (1000 * 3600 * 24);
  }
  buildSearch(targetDateField: string) {
    const search: Array<SFields | SConditionAND> = [];
    if (this.store.filters.dateFrom) {
      search.push({
        [targetDateField]: {
          $gte: new Date(this.store.filters.dateFrom).toDateString(),
        },
      });
    }
    if (this.store.filters.dateTo) {
      search.push({
        [targetDateField]: {
          $lte: new Date(this.store.filters.dateTo).toDateString(),
        },
      });
    }
    return {
      search: {
        $and: search,
      },
    };
  }

  updateSearchParams() {
    const searchPart = (targetDateField: string) => {
      return (
        's=' +
        encodeURI(JSON.stringify(this.buildSearch(targetDateField)?.search))
      );
    };
    this.searchDocuments = searchPart('issueDate');
    this.searchPayments = searchPart('date');
    this.searchDocumentPaymentLinks = searchPart('createdAt');
    this.timeDeltaMS =
      new Date(this.store.filters.dateTo).getTime() -
      new Date(this.store.filters.dateFrom).getTime();
  }

  @Watch('store.filters', {
    deep: true,
  })
  searchChange(value) {
    this.updateSearchParams();
  }
}
